.sui-inputarray {
  margin-bottom: 16px;
}

.sui-inputarray-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.skybolt-ui-input::placeholder {
  color: inherit;
  opacity: 0.3;
}
.skybolt-ui-input::placeholder {
  color: inherit;
  opacity: 0.3;
}.sui-inputhtml {
  position: relative;
}

.sui-inputhtml-content-wrapper {
  position: relative;
  padding-bottom: 2px;
}

.sui-inputhtml-content {
  font-family: proxima-nova, sans-serif;
  text-align: left;
}

.sui-inputhtml-content div[contenteditable=true] {
  min-height: 22px;
}

.sui-inputhtml-content div[contenteditable=true]:focus {
    outline: none;
}

.sui-inputhtml-content div[contenteditable=true]:empty:before{
  content: attr(data-placeholder);
  display: block;
  color: inherit;
  opacity: 0.3;
  text-transform: capitalize;
}

.sui-inputhtml-hint {
  padding-left: 6px;
  text-align: left;
  transition: left 60ms ease-in-out;
}

.sui-inputhtml-tools {
  position: absolute;
  top: -12px;
  right: 8px;
  background-color: #e0e0e0;
  border-radius: 2px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.sui-inputhtml-title {
  position: absolute;
  left: 6px;
  line-height: 1em;
  transition: all 130ms ease-in-out;
  pointer-events: none;
}

.sui-inputhtml-underline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
}

.sui-inputhtml-underline-fill {
  height: 2px;
  margin: 0 auto;
  transition: width 120ms ease-in-out;
}
.sui-input-input::placeholder {
  color: inherit;
  opacity: 0.3;
}